import { Box, BoxProps } from "@xcorejs/ui";
import RobeDoubleCarousel from "design-system/robe/Carousel/Double";
import { FC } from "react";
import ShortVideo, { ShortVideoRestructured } from "templates/products/ProductDetail/ShortVideo";
import { useLayout } from "xcore";
import { Video } from "xcore/types";
import VideoComponent, { VideoControls } from "./Video";

interface Props {
  videos: (Video | ShortVideoRestructured)[];
  keyFeaturesCarousel?: boolean;
  // setLoadedVideosIds?: Dispatch<SetStateAction<number[]>>;
  // renderThumbnailsExternally?: boolean;
}

const VideoCarousel: FC<Props & VideoControls & BoxProps> = ({
  videos,
  // setLoadedVideosIds,
  keyFeaturesCarousel,
  loop,
  autoplay,
  mute,
  openInModal,
  noControl,
  // renderThumbnailsExternally,
  ...props
}) => {
  const { stringify } = useLayout();

  return (
    <Box {...props}>
      {videos.length === 1
        // Vimeo video
        ? videos[0].type === "video"
          ? <VideoComponent video={videos[0]} {...{ loop, autoplay, mute, openInModal, noControl }} />
          : <ShortVideo video={videos[0].values} id={videos[0].id} />
          // TODO: clean later
          // : renderThumbnailsExternally
          // ? (
          //   <ShortVideoBase
          //     video={videos[0].values}
          //     id={videos[0].id}
          //     setLoadedVideosIds={setLoadedVideosIds}
          //   />
          // ) : <ShortVideo video={videos[0].values} id={videos[0].id} />

        : (
          <RobeDoubleCarousel
            labels={videos.map((v) => stringify(keyFeaturesCarousel ? v.title : v.values.title))}
          >
            {videos.map((v) =>
              v.type === "video"
                ? <VideoComponent key={v.id} video={v} moreVideos />
                : <ShortVideo key={v.id} video={v.values} id={v.id} />
              // TODO: clean later
              // v.type === "video"
              // ? <VideoComponent key={v.id} video={v} moreVideos />
              // : renderThumbnailsExternally
              //   ? (
              //     <ShortVideoBase
              //       key={v.id}
              //       video={v.values}
              //       id={v.id}
              //       setLoadedVideosIds={setLoadedVideosIds}
              //     />
              //   ) : <ShortVideo key={v.id} video={v.values} id={v.id} />

            )}
          </RobeDoubleCarousel>
        )}
    </Box>
  );
};

export default VideoCarousel;
