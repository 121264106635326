import { useXcoreQuery } from "@appiodev/xcore-client/xcore-ui";
import { WebUser } from "components/Layout/LoggedBar";
import { useMemo } from "react";
import { resolveFetchTimeout } from "./resolveFetchTimeout";
import { useRouter } from "next/router";

export const useUser = () => {
  const { locale } = useRouter();
  const { data: user, ...rest } = useXcoreQuery<WebUser>(cms =>
    cms.fetch("/api/web/login", undefined, resolveFetchTimeout(locale))
      .then(r => r.json())
      .catch(_ => null), {
    dedupingInterval: 20_000,
    ctx: {}
  });

  return useMemo(() => ({ user, ...rest }), [rest, user]);
};
