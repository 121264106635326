import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Relation } from "@appiodev/xcore-core";
import { Box, Button, Container, Flex, Img, Link as UILink, Stack, Strong, Typography } from "@xcorejs/ui";
import InnovationBox from "components/product/InnovationBox";
import { ProgressBarStyle } from "components/ProgressBar";
import RobeLoader from "components/RobeLoader";
import DropdownItem from "design-system/xcore/Dropdown/DropdownItem";
import { FC, useEffect, useMemo, useState } from "react";
import { useLayout } from "xcore";
import { Product, ProductOverviewPage } from "xcore/types";

import { resolveLocaleField, useLocalizedRelation } from "../../utils/useLocalizedRelation";
import { Accessory } from "../../xcore/types";
import SpecificationBox from "../product/SpecificationBox";

interface Props {
  active: boolean;
  pop?: ProductOverviewPage;
  empty?: boolean;
  close: () => unknown;
  prevent: () => unknown;
}

const CategoryDisplay: FC<Props> = ({ active, prevent, pop, empty, close }) => {
  const { stringify, file, general, richtextToString, value, cms } = useLayout();
  const [fetchedPop, setFetchedPop] = useState<ProductOverviewPage>();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (pop) {
      setFetchedPop(pop);
      setActive(resolveLocaleField("itemsRelation", pop.values, undefined as any, cms.locales.content)?.[0].id);
      setShowLoader(false);
    }
  }, [cms.locales.content, pop]);

  useEffect(() => {
    setTimeout(() => setShowLoader(true), 500);
  }, [active]);

  const items = useLocalizedRelation(
    "itemsRelation", fetchedPop?.values ?? {} as any, undefined as Relation<Product | Accessory | ProductOverviewPage>[] | undefined
  );

  const [activeItem, setActive] = useState(() => items?.[0]?.id);

  const item = useMemo(() => items?.find(c => c.id === activeItem)!, [items, activeItem]);

  if (empty) {
    return null;
  }

  return (
    <Box
      position="absolute"
      left={0}
      right={0}
      background="rgba(30, 30, 30, 0.5)"
      height="100%"
      opacity={active ? 1 : 0}
      pointerEvents={active ? null : "none"}
    >
      <Container
        px={0}
        py={0}
        background="#fff"
        maxHeight="100%"
        overflow="auto"
        minHeight="calc(100vh - 18rem)"
        onMouseEnter={prevent}
        onMouseLeave={close}
        boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.5)"
      >
        {pop ? (
          <Flex
            alignItems="flex-start"
            position="relative"
            bg="#fff"
            px="1.5rem"
            py="3rem"
            height="100%"
            zIndex={1004}
          >
            <Flex flexDirection="column">
              {items?.map(p => (
                <Link key={p.id} content={p}>
                  <DropdownItem
                    width="30rem"
                    onMouseEnter={() => setActive(p.id)}
                    {...p.id === activeItem && { bg: "lightGray", fontWeight: 600, color: "black" }}
                  >
                    {p.title}
                  </DropdownItem>
                </Link>
              ))}
            </Flex>
            {item && (
              <Flex>
                <Flex flex={{ _: "0 0 31.5rem", lg: "0 0 45rem" }} maxWidth={{ _: "31.5rem", sm: "45rem" }} mr="3rem">
                  <Link content={item}>
                    <UILink as="span" display="flex" alignItems="flex-start">
                      <Img
                        display="block"
                        width="100%"
                        mx="auto"
                        maxWidth="100%"
                        height="auto"
                        loading="lazy"
                        src={"images" in item.values ? file(item.values.images?.[0], "452x485") : undefined}
                        srcSet={"images" in item.values ? `${file(item.values.images[0], "452x485")} 1x, ${file(item.values.images[0], "452x485@2x")} 2x` : undefined}
                        alt={"images" in item.values ? stringify(item.values.name) : ""}
                      />
                    </UILink>
                  </Link>
                </Flex>
                <Flex flexDirection="column" height="100%">
                  <Typography variant="h3">
                    {"name" in item.values && item.values.name}
                  </Typography>
                  <Stack mt="2rem" direction="column" gap="1rem" px={{ _: "1.5rem", sm: "0" }}>
                    {"paramsOrFeatures" in item.values && item.values.paramsOrFeatures?.map((p, i) => (
                      <SpecificationBox
                        key={i}
                        name={stringify(p.type!.title)}
                        description={richtextToString(p.description)}
                        icon={file(p.type!.values.image)!}
                      />
                    ))}
                  </Stack>

                  {"innovations" in item.values && item.values.innovations && (
                    <>
                      <Strong display="block" mt="2rem" mb="1rem" lineHeight="2rem">Innovations</Strong>

                      <Flex flexWrap="wrap" mt="-1rem">
                        {item.values.innovations?.map(i => (
                          <InnovationBox
                            key={value(i.id)}
                            innovation={i}
                            mt="1rem"
                            mr="1rem"
                            openAs="link"
                          />
                        ))}
                      </Flex>
                    </>
                  )}

                  <Link content={item}>
                    <Button as="div" alignSelf="flex-start" mt="3rem" whiteSpace="nowrap">
                      {stringify(general.values.productDetailBtn)}
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex position="relative" width="100%" flexDirection="column">
            <Box
              width="100%"
              height="0.2rem"
              zIndex={1006}
            >
              <ProgressBarStyle
                bg="primary"
                height="100%"
                animation="load 1s forwards"
              />
            </Box>
            <Flex alignItems="center" justifyContent="center" height="100%" width="100%">
              {showLoader &&
              <RobeLoader width="38.4rem" />}
            </Flex>
          </Flex>
        )}
      </Container>
    </Box>
  );
};

export default CategoryDisplay;
