
import { FC, ReactNode, ReactElement } from "react";
import Carousel, { ResponsiveType } from "react-multi-carousel";
import styled from "styled-components";
import Dot from "design-system/robe/Carousel/Dot";
import { CarouselProps as CarouselPackageProps } from "react-multi-carousel/lib/types";
import ButtonGroup from "./ButtonGroup";
import { BoxProps, Box } from "@xcorejs/ui";

export type CarouselProps =
  {
    dots?: boolean;
    _dots?: BoxProps;
    customDot?: ReactElement | null;
    slides?: ReactNode[];
    infinite?: boolean;
    _carousel?: CarouselPackageProps;
    arrows?: boolean;
    responsive?: ResponsiveType;
    autoPlay?: boolean;
    autoPlaySpeed?: number;
  } & BoxProps;

const RobeCarousel: FC<CarouselProps> = (
  {
    dots,
    _dots,
    customDot,
    arrows,
    slides,
    infinite = true,
    _carousel,
    responsive,
    ...props
  }
) => {
  return (
    <RobeCarouselStyle bg="rgba(15, 31, 40, 0.75)" {...props}>
      <Carousel
        ssr
        swipeable
        renderDotsOutside
        responsive={responsive || {
          desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: 1
          },
          sm: {
            breakpoint: { max: 767, min: 480 },
            items: 1
          },
          xs: {
            breakpoint: { max: 479, min: 0 },
            items: 1
          }
        }}
        showDots={dots}
        customDot={customDot || <Dot />}
        arrows={false}
        customButtonGroup={arrows ? <ButtonGroup /> : undefined}
        renderButtonGroupOutside
        infinite={infinite}
        {..._carousel}
      >
        {slides!.map((_, index) =>
          slides![index]
        )}
      </Carousel>
    </RobeCarouselStyle>
  );
};

export default RobeCarousel;

export const RobeCarouselStyle = styled(Box)`
  position: relative;

  .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition;
  }

  .react-multi-carousel-dot-list {
    padding: 0;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }

  .react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    display: flex;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .react-multi-carousel-item {
      flex-shrink: 0 !important;
    }
    .react-multi-carousel-track {
      overflow: visible !important;
    }
  }
`;
