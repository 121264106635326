import { getXcoreConfig } from "@appiodev/xcore-core";
import type { Redis as RedisType, ValueType } from "ioredis";

let redis: RedisType;

export const ensureRedis = async () => {
  if (!redis) {
    const { default: Redis } = await import("ioredis");
    redis = new Redis({ db: +process.env.ROBE_REDIS_DB! || 2, password: process.env.REDIS_PASSWD });
  }
};

export const createCache = <TResult extends any, TArgs extends any[]>(
  get: (...args: TArgs) => Promise<TResult>,
  getKey: (...args: TArgs) => string,
  {
    serialize = r => Buffer.from(JSON.stringify(r), "utf8"),
    deserialize = b => JSON.parse(b.toString("utf8")),
    maxAge = 30000
  }: {
    serialize?: (r: TResult) => Buffer;
    deserialize?: (s: Buffer) => TResult;
    maxAge?: number;
  } = { }
): (...args: TArgs) => Promise<TResult> => {
  if (typeof window === "undefined") {
    return async (...args: TArgs) => {
      const config = await getXcoreConfig();
      if (config.etc?.robe?.bypassCache) {
        return get(...args);
      }

      await ensureRedis();

      const key = getKey(...args);

      const [cached, stat] = await Promise.all([await redis.getBuffer(key), await redis.get(`_keys:${key}`)]);

      // If the cache is not populated, wait for fresh value
      if (!cached) {
        const fresh = await get(...args);
        await refreshValue(key)(serialize(fresh));
        return fresh;
      }

      const age = Math.abs(+stat! - Date.now());

      // If the cache is stale, update the value
      if (age > maxAge) get(...args).then(serialize).then(refreshValue(key)).catch(() => null);

      return deserialize(cached);
    };
  }
  return get;
};

const refreshValue = (key: string) => async (value: ValueType) => {
  (process.env.NODE_ENV !== "production" || process.env.VERBOSE === "1") && console.info(`\x1b[33mredis\x1b[0m - updating value for ${key}`);
  await redis.set(`_keys:${key}`, Date.now());
  await redis.set(key, value);
};
