import { Img, useModal } from "@xcorejs/ui";
import VideoModal, { VideoPlayer } from "design-system/appio/VideoModal";
import { FC, useEffect, useState } from "react";
import { useLayout } from "xcore";
import { Video as VideoType } from "xcore/types";

import VideoPlaceholder from "./Placeholder";
import { useRouter } from "next/router";

interface Props {
  video: VideoType;
  moreVideos?: boolean;
}

export interface VideoControls {
  loop?: boolean;
  autoplay?: boolean;
  mute?: boolean;
  openInModal?: boolean;
  noControl?: boolean;
}

const Video: FC<Props & VideoControls> = ({ video, autoplay, mute, loop, noControl, openInModal, moreVideos }) => {
  const { stringify, file } = useLayout();
  const [playing, setPlaying] = useState(autoplay ?? video.values.autoPlay ?? false);
  const [open] = useModal(VideoModal, { url: stringify(video.values.videoURL), loop: loop ?? video.values.loop });
  const router = useRouter();

  useEffect(() => setPlaying(autoplay ?? video.values.autoPlay ?? false), [autoplay, router.asPath, video.values.autoPlay]);

  return playing
    ? (
      <VideoPlayer
        url={stringify(video.values.videoURL)}
        loop={loop ?? video.values.loop}
        noControl={noControl ?? video.values.noControl}
        mute={mute ?? video.values.mute}
        autoplay={autoplay ?? video.values.autoPlay}
      />
    ) : (
      <VideoPlaceholder ratio={9 / 16} onClick={() => openInModal ? open : setPlaying(true)}>
        <Img
          src={moreVideos ? file(video.values.videoThumbnail, "740x416") : file(video.values.videoThumbnail, "1290x726")}
          srcSet={moreVideos ? `${file(video.values.videoThumbnail, "740x416")} 1x,
            ${file(video.values.videoThumbnail, "740x416@2x")} 2x`
            : `${file(video.values.videoThumbnail, "1290x726")} 1x, ${file(video.values.videoThumbnail, "1290x726@2x")} 2x`}
          alt={stringify(video.values.internalDes)}
          width="100%"
          height="100%"
          loading="lazy"
        />
      </VideoPlaceholder>
    )
  ;
};

export default Video;
