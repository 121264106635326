import { Flex, Stack, Text } from "@xcorejs/ui";
import ExternalSVG from "components/ExternalSVG";
import IconBox, { IconBoxProps } from "design-system/xcore/IconBox";
import { FC, ReactNode } from "react";

export type SpecificationBoxProps = {
  name: string;
  description: ReactNode;
  background?: string;
  icon: string;
  _icon?: IconBoxProps;
};

const SpecificationBox: FC<SpecificationBoxProps> = (
  {
    name,
    description,
    background = "robe",
    icon,
    _icon,
    ...props
  }
) => (
  <Flex alignItems="flex-start" {...props}>
    <IconBox
      width="5rem"
      height="5rem"
      minWidth="5rem"
      minHeight="5rem"
      background={background}
      icon={<ExternalSVG src={icon} bg="#fff" borderRadius="50%" width="5rem" height="5rem" fill="#e00034" />}
      {..._icon}
    />

    <Stack ml="2rem" gap="0.2rem" direction="column">
      <Text variant="strong" fontSize="1.5rem" lineHeight="2rem">{name}</Text>
      <Text fontSize="1.5rem" lineHeight="2rem" wordBreak="break-word">{description}</Text>
    </Stack>
  </Flex>
);

export default SpecificationBox;
